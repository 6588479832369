import  React, { Component } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
//import { NavLink } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);

        this.toggleNav = this.toggleNav.bind(this);
        this.state = {
            isNavOpen: false
        };
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    render() {
        return (
            <div>
                <Navbar color="light" light expand="md">
                    <NavbarBrand href="/">Quota</NavbarBrand>                   
                        <p>     Generate. Make. Inspire.</p>
                </Navbar>
            </div>
        );
    }
}

export default Header;