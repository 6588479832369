import '../index.css'

export function wrapText(context, canvas, text, x, y, maxWidth, lineHeight, textColour) {
    var words = text.split(' ');
    var line = '';
    var line2 = ''
    context.fillStyle = textColour;
    //context.font = "200 80px Barlow Semi Condensed";
    var quote_space = 0


    //re-calculate y
    for (var i = 0; i < words.length; i++) {
        var testLine2 = line2 + words[i] + ' ';
        var metrics2 = context.measureText(testLine2);
        var testWidth2 = metrics2.width;

        if ((testWidth2 > maxWidth && i > 0) || words[i] === '--') {
            line2 = words[i] + ' ';
            quote_space += lineHeight;
        }
        else {
            line2 = testLine2;
            
        }
    }

    y = (canvas.height-quote_space-lineHeight)/2

    //wrap text
    for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;

        if (words[n] === '--'){
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += 2*lineHeight;
        }
        else if (testWidth > maxWidth && n > 0) {
            /*context.shadowOffsetX = 5;
            context.shadowOffsetY = 5;
            context.shadowColor = "black";
            context.shadowBlur = 4;*/
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
        }
        else {
            line = testLine;
        }
    }
    //drop shadow
    /*context.shadowOffsetX = 5;
    context.shadowOffsetY = 5;
    context.shadowColor = "black";
    context.shadowBlur = 8;*/
    context.fillText(line, x, y);
}

export const invertRGB = (colour) => {
    var pixels = "rgba("
    var cleanColour = colour.slice(4, -1)
    var clean = cleanColour.split(", ")

    pixels = pixels + ((255 - clean[0]).toString()) + ", " + ((255 - clean[1]).toString()) + ", " + ((255 - clean[2]).toString()) + ", 0.4)"
    return pixels

}

export const textBackground = (ctx, canvas, colour) => {
    var x, y, width, height
    ctx.beginPath();
    if(canvas.width===canvas.height) {
        x = canvas.width * 0.075
        y = canvas.height * 0.075
        width = canvas.width * 0.85
        height = canvas.height * 0.85

    } else if (canvas.width > canvas.height) {
        x = canvas.width * 0.075
        y = canvas.height * 0.125
        width = canvas.width * 0.85
        height = canvas.height * 0.75
    }else {
        x = canvas.width * 0.075
        y = canvas.height * 0.04
        width = canvas.width * 0.85
        height = canvas.height * 0.92
    }

    ctx.rect(x, y, width, height)
    ctx.closePath()
    ctx.fillStyle = invertRGB(colour)
    ctx.fill()


}

export function invert(data, ctx, imageData) {
    for (var i = 0; i < data.length; i += 4) {
        data[i] = 255 - data[i];     // red
        data[i + 1] = 255 - data[i + 1]; // green
        data[i + 2] = 255 - data[i + 2]; // blue
    }
    ctx.putImageData(imageData, 0, 0);
}



export function setCanvas(width, height, imgWidth, imgHeight, canvas) {

    var sh, sw, sx, sy
  
    if (width === height){
        if (imgHeight>imgWidth) {
            sh = imgWidth
            sw = imgWidth
            sx = 0
            sy = imgHeight-sh
            
        }
        else {
            sh = imgHeight
            sw = imgHeight
            sx = (imgWidth-sw)/2
            sy = 0
            
        }
    }else if (width > height) {
        if ((imgWidth/imgHeight)>(1600/900)) {
            console.log("case 1")
            sh = imgHeight
            sw = parseInt((16/9)*imgHeight)
            sx = (imgWidth-sw)/2
            sy = 0
        }
        else {
            if (imgWidth>imgHeight){
                console.log("case 2")
                sh = parseInt((9 / 16) * imgWidth)
                sw = imgWidth
                sx = 0
                sy = imgHeight - sh
                
            } else {
                console.log("case 3")
                sh = parseInt((9/16) * imgWidth)
                sw = imgWidth
                sx = 0
                sy = imgHeight - sh
            }
        }
    }
    else if (width < height){
        if ((imgHeight / imgWidth) > (1600 / 900)) {
            sw = imgWidth
            sh = imgWidth * (9/16)
            sx = 0
            sy = imgHeight - sh
        }
        else{
            sh = imgHeight
            sw = imgHeight * (9/16)
            sy = 0
            sx = (imgWidth-sw)/2

        }
       }
    return[sh, sw, sx, sy];
    }


