import * as ActionTypes from './ActionTypes';

export const Sizes = (state = {
    isLoading: false,
    errMess: null,
    sizes: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_SIZES:
            return { ...state, isLoading: false, errMess: null, sizes: action.payload };

        case ActionTypes.SIZES_LOADING:
            return { ...state, isLoading: true, errMess: null, sizes: [] };

        case ActionTypes.SIZES_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};