import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createForms } from 'react-redux-form';
//import {Pictures} from './pictures';
import { Quotes } from './quotes';
import { Sizes } from './sizes';
import { Image } from './images';
import { selectedSize } from './selectedSize';
import { InitialFeedback } from './forms'

const logger = createLogger();
let middleware = [];
if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, thunk, logger];
} else {
    middleware = [...middleware, thunk];
}

export const ConfigureStore = () => {
    const store = createStore(
        //Root Reducer
        combineReducers({
            sizes: Sizes,
            quotes: Quotes,
            selectedSize: selectedSize,
            image: Image,
            ...createForms({
                feedback: InitialFeedback
            })
        }),
        compose(applyMiddleware(...middleware))
    );

    return store;
}