import * as ActionTypes from './ActionTypes'

export const Image = (state = {
    isLoading: false,
    errMess: null,
    image: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_IMAGE:
            return { ...state, isLoading: false, errMess: null, image: action.payload };

        case ActionTypes.IMAGE_LOADING:
            return { ...state, isLoading: true, errMess: null, image: [] };

        case ActionTypes.IMAGE_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};