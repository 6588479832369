import * as ActionTypes from './ActionTypes';
import { baseUrl } from '../shared/baseUrl';
import { firestore, /*firebasestore*/ } from '../firebase/firebase';


//load random quote

//add theme
export const addTheme = (theme) => (dispatch) => {

    dispatch(quoteLoading(true))


    var docRef =  firestore.collection(theme)
    docRef.where("Length", "<=", 30).orderBy("Length", "desc").limit(1).get()
        .then(snapshot => {
            var quote_length = snapshot.docs[0].id
            var x = Math.floor((Math.random() * quote_length) + 1);
    
            var newRef = firestore.collection(theme).where("id", "==", x)
            newRef.get()
                .then(snap => {
                    if (snap.docs[0].exists) {
                        snap.forEach(doc =>{
                        dispatch(addQuote(doc.data()))
                        })
                    } else {
                        console.log("No such Document")
                    }
                })
                .catch(error => dispatch(quoteFailed(error.message)))
        })
        .catch(error => dispatch(quoteFailed(error.message)))
        }
        

export const addQuote = (quote) => ({
    type: ActionTypes.ADD_QUOTE,
    payload: quote
})

export const quoteLoading = () => ({
    type: ActionTypes.QUOTE_LOADING
})

export const quoteFailed = (errmess) => ({
    type: ActionTypes.QUOTE_FAILED,
    payload: errmess
})

export const fetchSizes = () => (dispatch) => {
    dispatch(sizesLoading(true));

    return fetch(baseUrl + 'sizes')
        .then(response => {
            if (response.ok) {
                return response;
            }
            else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(sizes => dispatch(addSizes(sizes)))
        .catch(error => dispatch(sizesFailed(error.message)));
}

export const sizesLoading = () => ({
    type: ActionTypes.SIZES_LOADING
})

export const addSizes = (sizes) => ({
    type: ActionTypes.ADD_SIZES ,
    payload: sizes
})

export const sizesFailed = (errmess) =>({
    type: ActionTypes.SIZES_FAILED,
    payload: errmess
})

export const addSelectedSize = (size) => ({
    type: ActionTypes.ADD_SELECTED_SIZE,
    payload: size
})

export const randomImage = () => (dispatch) => {

    dispatch(imageLoading(true));

    var docRef = firestore.collection("images")
    docRef.orderBy("id", "desc").limit(1).get()
        .then(snapshot => {
            var image_length = snapshot.docs[0].id

            var x = Math.floor((Math.random() * image_length) + 1)

            var newRef = firestore.collection("images").where("id", "==", x)
            newRef.get()
                .then(snap=>{
                    if (snap.docs[0].exists) {
                        snap.forEach(doc => {

                            dispatch(addImage(doc.data()))
                        })
                    } else {
                        console.log("No such Document")
                    }
                }).catch(error => dispatch(imageFailed(error.message)))
        }).catch(error => dispatch(imageFailed(error.message)))

    /*return fetch(baseUrl + `image-list/${x}`)
        .then(response => {
            if (response.ok) {
                return response;
            }
            else {
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            }
        },
            error => {
                var errmess = new Error(error.message);
                throw errmess;
            })
        .then(response => response.json())
        .then(image => dispatch(addImage(image)))
        .catch(error => dispatch(imageFailed(error.message)));*/
}

export const addImage = (image) => ({
    type: ActionTypes.ADD_IMAGE,
    payload: image

})

export const imageFailed = (errmess) => ({
    type: ActionTypes.IMAGE_FAILED,
    payload: errmess
})

export const imageLoading = () =>({
    type: ActionTypes.IMAGE_LOADING
})