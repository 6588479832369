import React, { Component } from 'react';
import Home from './HomeComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import RandomQuote from './RandomQuoteComponent';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { addTheme, /*fetchSizes,*/ addSelectedSize, randomImage } from '../redux/ActionCreators'

const mapStateToProps = state =>{
    return{
        quotes: state.quotes,
        theme: state.theme,
        //sizes: state.sizes,
        image: state.image,
        selectedSize: state.selectedSize
    }
}

const mapDispatchToProps = dispatch => ({
    addTheme: (theme)=> dispatch(addTheme(theme)),
    addSelectedSize: (size)=> dispatch(addSelectedSize(size)),
    randomImage: ()=>{dispatch(randomImage())},
    //fetchSizes: ()=> {dispatch(fetchSizes())}
})


class Main extends Component {

    componentDidMount() {
        //this.props.fetchSizes()
    }

    

    render() {

        const HomePage = () => {
            return (
                <Home
                    theme={this.props.theme}
                    quotes={this.props.quotes}
                    //sizes={this.props.sizes}
                    image={this.props.image}
                    selectedSize={this.props.selectedSize}
                    addTheme={this.props.addTheme}
                    addSelectedSize={this.props.addSelectedSize}
                    randomImage={this.props.randomImage}
                />
            )
        }; 

        return (
            <div>
                <Header />
                    <Switch location={this.props.location}>>
                        <Route path="/" component={HomePage} />
                        <Route path="/random/:quoteId" component={() => <RandomQuote loc={this.props.location} random_quote={this.props.quotes}/>} />
                        <Redirect to="/" />
                    </Switch>
                <Footer />
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));