import React, { Component } from 'react';
import SelectionScreen from './SelectionScreenComponent';


class Home extends Component {



    render() {
      

        return(
            <div className="container-fluid fill bg">
                <SelectionScreen quote={this.props.quotes} addTheme={this.props.addTheme} 
                /*sizes={this.props.sizes} */selectedSize={this.props.selectedSize} 
                addSelectedSize={this.props.addSelectedSize} randomImage={this.props.randomImage}
                image={this.props.image}
                
                />
            </div>
        )
    }
}

export default Home