export const LOAD_QUOTE = 'LOAD_QUOTE';
export const QUOTE_LOADING = 'QUOTE_LOADING';
export const QUOTE_FAILED = 'QUOTE_FAILED';
export const ADD_THEME = 'ADD_THEME';
export const ADD_QUOTE = 'ADD_QUOTE';
export const SIZES_LOADING = 'SIZES_LOADING';
export const SIZES_FAILED = 'SIZES_FAILED';
export const ADD_SIZES = 'ADD_SIZES';
export const ADD_SELECTED_SIZE = 'ADD_SELECTED_SIZE';
export const SELECTED_SIZE_LOADING = 'SELECTED_SIZE_LOADING';
export const SELECTED_SIZE_FAILED = 'SELECTED_SIZE_FAILED';
export const ADD_IMAGE = 'ADD_IMAGE';
export const RANDOM_IMAGE = 'RANDOM_IMAGE';
export const IMAGE_LOADING = 'IMAGE_LOADING';
export const IMAGE_FAILED = 'IMAGE_FAILED';

