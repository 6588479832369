import {config} from './config';
import firebase from 'firebase/app';
import  'firebase/firebase-firestore';
import 'firebase/firebase-storage'

firebase.initializeApp(config);

//export const auth = firebase.auth;
//export const fireauth = firebase.auth;


export const firestore = firebase.firestore();

export const firebasestore = firebase.firestore;