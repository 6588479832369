import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
//import { LocalForm, Control } from 'react-redux-form';
//import {Link} from 'react-router-dom';
//import {baseUrl} from '../shared/baseUrl';
import {Loading} from './LoadingComponent';
import RandomQuote from './RandomQuoteComponent';
import { FadeTransform } from 'react-animation-components';



function RenderThemes({allthemes, rand_quote, handleChange, setTheme, selectedTheme}) {
  
        return (
                <div>
                    <DropdownToggle caret className="drop" id='themedrop'>
                        {selectedTheme}
                    </DropdownToggle>
                    <DropdownMenu>
                        {allthemes.map((y) => {
                            return (
                                <DropdownItem key={y.id} onClick={() => handleChange(y.theme)}>{y.theme}</DropdownItem>
                            )
                        })
                        }
                    </DropdownMenu>
                </div >
                    )
}

function RenderColours({allcolours,setColour, selectedColour }) {
    return(
        <div>
            <DropdownToggle caret className="drop">
                {selectedColour}
            </DropdownToggle>
            <DropdownMenu>
                {allcolours.map((x)=>{
                    return (
                        <DropdownItem disabled key={x.id} onClick={()=>setColour(x.colour)}>{x.colour} (Coming Soon)</DropdownItem>
                    )
                })}
                
            </DropdownMenu>
        </div>
    )
}

 
function RenderSizes({ allsizes, setSize, selectedSize }) {
    return (
        <div>
            <DropdownToggle caret className="drop">
                {selectedSize.name}
            </DropdownToggle>
            <DropdownMenu>
                {allsizes.map((x) => {
                    return (
                        <DropdownItem key={x.id} onClick={() => setSize(x.name, x.size.width, x.size.height)}>{x.name}</DropdownItem>
                    )
                })}

            </DropdownMenu>
        </div>
    )
} 


class SelectionScreen extends Component {

    componentDidMount() {
        if ((this.props.quote.quotes.Category !== undefined)&&(this.props.image.image.name !== undefined)){
            var url = document.getElementById("canvasImg").src
        this.setState({
            shareUrl: url
        })
        }
    }

    defaultState = {
        dropdownOpen: false,
        dropdown2Open: false,
        dropdown3Open: false,
        selectedTheme: "Theme",
        selectedColour: "Background",

        display: "none",
        newTo: {
            pathname: '/random/3432'
        },
        themeList: [{ id: 1, theme: "Inspirational"}, {id: 2, theme: "Life"},{id: 3, theme: "Love"},{id: 4, theme: "Motivational"}, {id: 5, theme: "Positive"}],
        colourList: [{id: 1, colour: "White"}, {id: 2, colour: "Black"}, {id: 3, colour: "Abstract"}, {id: 4, colour: "Random Colour"}, {id: 5, colour: "Random Gradient"}],
        sizeList: [{id: 1, name: "1:1 (Square)", size: {width: 1000, height: 1000}},{id: 2, name: "9:16 (Portrait)", size: {width: 900, height: 1600}},{id: 3, name: "16:9 (Landscape)", size: {width: 1600, height: 900}} ],
        imageSize: {
            width: 1000,
            height: 1000,
            name: 'Size'
        },
        shareUrl: ""

    }
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setColour = this.setColour.bind(this);
        this.setTheme = this.setTheme.bind(this);
        this.setSize = this.setSize.bind(this);
        this.state = this.defaultState;
    }

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    toggle2() {
        this.setState({
            dropdown2Open: !this.state.dropdown2Open
        });
    }
    toggle3() {
        this.setState({
            dropdown3Open: !this.state.dropdown3Open
        });
    }
    
    handleChange= (theme) => {
        this.setState({
            selectedTheme: theme
        })
        }

    setColour = (colour) => {
        this.setState({
            selectedColour: colour
        })
    }

    setSize = (name, width, height) => {
        this.setState({
            imageSize: {
                width: width,
                height: height,
                name: name
            }
        })
    }

    setTheme(theme, size) {
        this.props.addTheme(theme);
        this.props.addSelectedSize(size);
        this.props.randomImage();
    }

    render() {
        if (this.props.quote.isLoading || this.props.image.isLoading) {
            return(
                <div className = "row loading-wheel align-items-center justify-content-center">
                    <Loading/>
                </div>
            )

        } else if (this.props.quote.quotes.Quote===undefined){
            return (

                <div >
                    <div className="row big-logo align-items-center justify-content-center  ">
                        <div className="col-lg-4 col-md-7">
                        <img src="assets/images/logo.svg" alt='Quota' />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm text-center drop-button">
                            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                <RenderThemes allthemes={this.state.themeList} handleChange={this.handleChange} setTheme={this.setTheme} rand_quote={this.props.quote.quotes} selectedTheme={this.state.selectedTheme} />
                            </Dropdown>
                        </div>
                        
                        <div className="col-sm text-center drop-button">
                            <Dropdown isOpen={this.state.dropdown2Open} toggle={this.toggle2}>
                                <RenderColours allcolours={this.state.colourList} setColour = {this.setColour} selectedColour={this.state.selectedColour} />
                            </Dropdown>
                        </div>
                        
                        <div className="col-sm text-center drop-button">
                            <Dropdown isOpen={this.state.dropdown3Open} toggle={this.toggle3}>
                                <RenderSizes allsizes={this.state.sizeList} setSize={this.setSize} selectedSize={this.state.imageSize}/>
                            </Dropdown>
                        </div>
                    </div>
                    <div className="row generate align-items-center justify-content-center">
                        <Button className ="gen-button" onClick={() => this.setTheme(this.state.selectedTheme, this.state.imageSize)}>Generate!</Button>
                    </div>
                </div>
            )
        }
        else if(this.props.quote.quotes.Category!==undefined){
            return(
                <div>
                    <FadeTransform in
                        transformProps={{
                            exitTransform: 'scale(0.5) translateY(-60%)'
                        }}>
                        <div className="row align-items-center justify-content-center">
                            <RandomQuote quote={this.props.quote.quotes.Quote} 
                            author={this.props.quote.quotes.Author} category={this.props.quote.quotes.Category} 
                            size={this.props.selectedSize.selectedSize} image={this.props.image.image}
                            
                            />
                        </div>
                    
                        <div className="row second-select align-items-center">
                            <div className="col-sm text-center drop-button">
                                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                    <RenderThemes allthemes={this.state.themeList} handleChange={this.handleChange} setTheme={this.setTheme} rand_quote={this.props.quote.quotes} selectedTheme={this.state.selectedTheme} />
                                </Dropdown>
                            </div>
                            
                            <div className="col-sm text-center drop-button">
                                <Dropdown isOpen={this.state.dropdown2Open} toggle={this.toggle2}>
                                    <RenderColours allcolours={this.state.colourList} setColour={this.setColour} selectedColour={this.state.selectedColour} />
                                </Dropdown>
                            </div>
                            
                            <div className="col-sm text-center drop-button">
                                <Dropdown isOpen={this.state.dropdown3Open} toggle={this.toggle3}>
                                    <RenderSizes allsizes={this.state.sizeList} setSize={this.setSize} selectedSize={this.state.imageSize} />
                                </Dropdown>
                            </div>
                        </div>
                        <div className="row generate-under align-items-center justify-content-center">
                            <Button className="gen-button" onClick={() => this.setTheme(this.state.selectedTheme, this.state.imageSize)}>Generate!</Button>
                        </div>
                    </FadeTransform>

                </div>

            )
        }
        else return(<div></div>)

        
    }
}


export default SelectionScreen;
