import React, { Component } from 'react';
//import { Loading} from './LoadingComponent';
import { wrapText, setCanvas, textBackground } from './CanvasFunctions';
import {Button} from 'reactstrap';
//import { TwitterShareButton , FacebookShareButton, /*  TumblrShareButton*/ } from 'react-share';
import firebase from 'firebase/app';
import 'firebase/firebase-firestore';
import 'firebase/firebase-storage';
import '../index.css'






class RandomQuote extends Component {

    constructor(props) {
        super(props);
        this.deviceShare = this.deviceShare.bind(this);
        this.state = {
           // sharedUrl: "",
            pngUrl: "",
            imgBlob: null,
            blobUrl: ""
          }

    }
    componentDidMount() {
        if (this.props.category !== undefined) {

            if (this.props.size.width>this.props.size.height) {
                if (window.innerWidth > 992) {
                    document.getElementById("canvas-wrap").style.maxWidth = "60%"
                    document.getElementById("canvas-wrap").style.flex = "70%"
                } else{
                    document.getElementById("canvas-wrap").style.maxWidth = "100%"
                    document.getElementById("canvas-wrap").style.flex = "70%"
                }
            } else if (this.props.size.width<this.props.size.height) {
                if (window.innerWidth > 992) {
                    document.getElementById("canvas-wrap").style.maxWidth = "25%"
                    document.getElementById("canvas-wrap").style.flex = "25%"
                }
            }

            const canvas = this.refs.canvas;
            const ctx = canvas.getContext("2d");
            canvas.width = this.props.size.width;
            canvas.height = this.props.size.height;
            canvas.style.width = '100%'
            canvas.style.height = '100%';
            ctx.textAlign = "center";
            ctx.font = "300 80px Barlow Semi Condensed";
            var textColour = this.props.image.text_colour;
                   
            var maxWidth = this.props.size.width*0.8;
            var lineHeight = 90;
            var x = (canvas.width - maxWidth) + this.props.size.width*0.3;

            //Set starting point according to canvas length
            var y
            if (this.props.size.height === 1000) {
                y = canvas.height/3
            } else {
                y = canvas.height / 2.5;
            }
            
            var text = this.props.quote+ ' -- ' + this.props.author;  
            
            var imgObj = new Image()
            imgObj.crossOrigin = "anonymous"
            
            let [sh, sw, sx, sy] = setCanvas(this.props.size.width, this.props.size.height, this.props.image.width, this.props.image.height, canvas)

            imgObj.onload = () => {
                var destWidth = canvas.width
                var destHeight = canvas.height
                var destX = 0
                var destY = 0
                ctx.drawImage(imgObj, sx, sy, sw, sh, destX, destY, destWidth, destHeight)
                textBackground(ctx, canvas, textColour)
                wrapText(ctx, canvas, text, x, y, maxWidth, lineHeight, textColour)

                var dataURL = canvas.toDataURL()
                
                fetch(dataURL)
                    .then(res => res.blob())
                    .then(blob =>{
                        var blobUrl
                        blobUrl = URL.createObjectURL(blob)
                        this.setState({
                            imgBlob: blob,
                            blobUrl: blobUrl
                        })
                    }
                        )
                    .catch(error => (console.log(error)))
                this.setState({
                    pngUrl: dataURL
                })
                /*var data = dataURL.split(',')[1]
                data = data.replace(/\s/g, '');
                var imgName = Math.floor((Math.random() * 100000) + 1);
                var imgRef = storage.ref('generated').child(imgName.toString() + '.jpg')
                var dURL
                
                imgRef.putString(data, 'base64', { contentType: 'image/jpg'})
                    .then(snap => { 
                        imgRef.getDownloadURL()
                            .then(url => {
                                dURL = url; 
                                this.setState({
                                    sharedUrl: dURL
                                });
                            })
                        }).catch(error => { console.log(error) })
                    .catch(error=>{console.log(error)})

                this.setState({
                    sharedUrl: dURL
                })*/
            }
            if (this.props.image.name!==undefined){
                var storage = firebase.storage()
                var pathRef = storage.ref('images')
                pathRef.child(this.props.image.name).getDownloadURL()
                .then(url=>{imgObj.src = url})
            }
        }
    }

    deviceShare = (file) => {

        if (navigator.canShare && navigator.canShare({files: file})){
            
            navigator.share({
                files: file,
                title: `Quote by ${this.props.author} \n#Quota`,
                text: 'quota.design'
            }).then(() => console.log('Share was successful.'))
                .catch((error) => {
                    document.getElementById('error').innerHTML=error
                    console.log('Sharing failed', error)
                    });
        } else {
            console.log(`You cannot share this file: `);
            document.getElementById('error').innerHTML = "Cannot share"
        }
        
    }

    render() {
        if (navigator.canShare) {
            return (
                <div id="canvas-wrap" className="col-lg-5 col-md-7 col-sm-3">
                    <canvas ref="canvas" id="myCanvas" style={canvasStyle} />
                    <img width="100%" height="100%" id="canvasImg" alt="Right Click To Download Quote" style={{ display: "None" }} />
                    <div className="row align-items-center justify-content-center">
                        <div>
                            <Button color="link" className="download" onClick={() => this.deviceShare([new File([this.state.imgBlob], 'quote.png', {type: 'image/png'})])} style={{marginRight: "20px"}}><i className="fa fa-share-alt"></i></Button>
                            <a role="button" id="androiddload" className="download"  href={this.state.blobUrl} download="quote.png"><i className="fa fa-download"></i></a>
                        </div>
                    </div>
                    <div id='error'></div>
                </div>
            )
        } else {
        return(
            <div id="canvas-wrap"className="col-lg-5 col-md-7 col-sm-3">
                <canvas ref="canvas" id="myCanvas" style={canvasStyle} />
                <img width="100%" height="100%" id="canvasImg" alt="Right Click To Download Quote" style={{display: "None"}} />
                <div className="row align-items-center justify-content-center">
                    <div>
                        <a role="button" className="download" href={this.state.blobUrl} download="quote.png"><i className="fa fa-download"></i></a>
                        {/*<TwitterShareButton style={{ marginRight: "20px", marginLeft: "20px" }} url={this.state.} title={`Quote by ${this.props.author} \n#Quota`}><i className="fa fa-twitter"></i></TwitterShareButton>
                        <FacebookShareButton id="facebook" url={this.state.blobUrl} title={`Quote by ${this.props.author} \n#Quota`}><i className="fa fa-facebook"></i></FacebookShareButton>*/}
                    </div>
                </div>
            </div>
        )}
    }
}

var canvasStyle = {
    WebkitFontSmoothing: 'antialiased',
    marginTop: '7%'
}

export default RandomQuote;
