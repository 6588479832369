import * as ActionTypes from './ActionTypes';

export const selectedSize = (state = {
    isLoading: false,
    errMess: null,
    selectedSize: []
}, action) => {
    switch (action.type) {
        case ActionTypes.ADD_SELECTED_SIZE:
            return { ...state, isLoading: false, errMess: null, selectedSize: action.payload };

        case ActionTypes.SELECTED_SIZE_LOADING:
            return { ...state, isLoading: true, errMess: null, selectedSize: [] };

        case ActionTypes.SELECTED_SIZE_FAILED:
            return { ...state, isLoading: false, errMess: action.payload };

        default:
            return state;
    }
};