import * as ActionTypes from './ActionTypes'

export const Quotes = (state = {isLoading: false, 
    errMess: null,
    quotes:[]}, action) => {
        switch (action.type) {
            case ActionTypes.ADD_QUOTE:
                return {...state, isLoading: false, errMess: null, quotes: action.payload};

            case ActionTypes.QUOTE_LOADING:
                return {...state, isLoading: true, errMess: null, quotes: []};

            case ActionTypes.QUOTE_FAILED:
                return {...state, isLoading: false, errMess: action.payload};
                
            default:
                return state;
        }
    };